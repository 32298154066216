<template>
    <div class="chart-wrapper">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <ApexChart
                v-else-if="series.length"
                :height="chartHeight"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { formatHelper, httpHelper } from '@/utils'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

const colors = {
    [true]: '#41b883',
    [false]: '#00a1de',
}

export default {
    name: 'BleHistoryChartView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        fullHeight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            assetId: 0,
            isGateway: false,
            series: [],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: (_, { dataPointIndex, seriesIndex }) => {
                        return formatHelper.hoursAndMinutesDuration(
                            this.series[seriesIndex].data[dataPointIndex].meta
                        )
                    },
                },
                tooltip: {
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss',
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    showForNullSeries: false,
                    labels: {
                        formatter: value => {
                            const durations = this.series.map(({ data }) => {
                                const duration = data
                                    .filter(({ x }) => x === value)
                                    .reduce((time, { meta }) => {
                                        return time + meta
                                    }, 0)

                                if (duration) {
                                    return formatHelper.hoursAndMinutesDuration(
                                        duration
                                    )
                                }
                            })

                            return [value, ...durations.filter(Boolean)]
                        },
                    },
                    min: min => {
                        return Math.max(
                            this.customRange.startDate.getTime(),
                            min
                        )
                    },
                    max: max => {
                        return Math.min(this.customRange.endDate.getTime(), max)
                    },
                },
                legend: {
                    showForSingleSeries: true,
                    showForNullSeries: false,
                    showForZeroSeries: false,
                    markers: {
                        fillColors: ['#41b883', '#00a1de'],
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes', 'trackers']),
        chartHeight() {
            const rowCount = this.series.reduce((acc, cur) => {
                cur.data.forEach(({ x }) => acc.add(x))
                return acc
            }, new Set()).size
            return 400 + 80 * Math.max(0, rowCount - 4)
        },
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
    },
    mounted() {
        const id = +this.$route.params.id
        const tracker = this.trackers.find(item => item.id === id)
        this.assetId = tracker?.asset
        this.isGateway = tracker?.is_ble_gateway
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        async load() {
            this.isLoading = true
            const results = await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let asset_filter = `beacon=${this.assetId}`
            if (this.isGateway) {
                asset_filter = `gateway=${this.assetId}`
            }
            let url =
                'ble/beacon-history/?' +
                asset_filter +
                `&start=${encodeURIComponent(
                    moment(this.customRange.startDate).format()
                )}` +
                `&end=${encodeURIComponent(
                    moment(this.customRange.endDate).format()
                )}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = results.concat(data.results)
                url = data.next
            }

            return results
        },
        setData(results) {
            this.series = results.reduce((acc, cur) => {
                if (cur.first_seen === cur.last_seen) {
                    return acc
                }

                const bucket = acc.find(item => item.finished === cur.finished)
                const dataItem = {
                    x: this.isGateway ? cur.beacon.name : cur.gateway.name,
                    y: [
                        new Date(cur.first_seen).getTime(),
                        new Date(cur.last_seen).getTime(),
                    ],
                    ...{
                        meta: cur.duration,
                    },
                }

                if (bucket) {
                    bucket.data.push(dataItem)
                } else {
                    acc.push({
                        finished: cur.finished,
                        name: cur.finished
                            ? this.$t('finished', {
                                  bleAsset: this.isGateway
                                      ? 'Beacon'
                                      : 'Gateway',
                              })
                            : this.$t('notFinished', {
                                  bleAsset: this.isGateway
                                      ? 'Beacon'
                                      : 'Gateway',
                              }),
                        data: [dataItem],
                        color: colors[cur.finished],
                    })
                }

                return acc
            }, [])
        },
    },
}
</script>

<i18n>
{
    "en": {
        "notFinished": "{bleAsset} within reach",
        "finished": "{bleAsset} no longer within reach"
    },
    "de": {
        "notFinished": "{bleAsset} in Reichweite",
        "finished": "{bleAsset} nicht mehr in Reichweite"
    },
    "fr": {
        "notFinished": "{bleAsset} à portée",
        "finished": "{bleAsset} plus à portée"
    },
    "it": {
        "notFinished": "{asset} a portata di mano",
        "finished": "{asset} non più a portata di mano"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-wrapper {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.filter {
    margin-left: 1rem;

    button {
        max-width: 120px;
    }
}

.datepicker {
    width: 20%;
    min-width: 350px;
}

@include respond-to('for-tablet-down') {
    .chart-wrapper {
        display: block;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }

    .filter {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .chart {
        border: none;
    }
}
</style>
